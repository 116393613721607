(function() {
    'use strict';

    angular
        .module('bozorakaAdminApp')
        .controller('AgentDialogController', AgentDialogController);

    AgentDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'entity', 'Agent'];

    function AgentDialogController ($timeout, $scope, $stateParams, $uibModalInstance, entity, Agent) {
        var vm = this;

        vm.partner = entity;
        vm.isSuperAgent = false;
        vm.clear = clear;
        vm.save = save;

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function save () {
            vm.isSaving = true;
            vm.partner.authorityName = vm.isSuperAgent ? "ROLE_SUPER_AGENT" : "ROLE_AGENT";
            if (vm.partner.id !== null) {
                Agent.update(vm.partner, onSaveSuccess, onSaveError);
            } else {
                Agent.save(vm.partner, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            $scope.$emit('bozorakaAdminApp:partnerUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }
    }
})();
