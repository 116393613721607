(function () {
    'use strict';

    angular
            .module('bozorakaAdminApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
            .state('market-stats-daily', {
                parent: 'entity',
                url: '/market-stats-daily',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_ANALYST'],
                    pageTitle: 'global.menu.stats.daily'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/market-stats/market-stats-daily.html',
                        controller: 'MarketStatsDailyController',
                        controllerAs: 'vm'
                    }
                },

                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('statsMerchants');
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }]
                }
            })
            .state('market-stats-periodical', {
                parent: 'entity',
                url: '/market-stats-periodical',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_ANALYST'],
                    pageTitle: 'global.menu.stats.periodical'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/market-stats/market-stats-periodical.html',
                        controller: 'MarketStatsPeriodicalController',
                        controllerAs: 'vm'
                    }
                },

                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('statsMerchants');
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }]
                }
            })
            .state('market-stats-top-selling', {
                parent: 'entity',
                url: '/market-stats-top-selling',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_ANALYST'],
                    pageTitle: 'global.menu.stats.productsSold'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/market-stats/market-stats-top-selling.html',
                        controller: 'MarketStatsTopSellingController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('statsMerchants');
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }]
                }
            })

            .state('market-stats-weekly', {
                parent: 'entity',
                url: '/market-stats-weekly',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_ANALYST'],
                    pageTitle: 'global.menu.stats.weekly'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/market-stats/market-stats-weekly.html',
                        controller: 'MarketStatsWeeklyController',
                        controllerAs: 'vm'
                    }
                },

                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('statsMerchants');
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }]
                }
            })

            .state('market-stats-shipping-fee', {
                parent: 'entity',
                url: '/market-stats-shipping-fee',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_ANALYST'],
                    pageTitle: 'global.menu.stats.shippingFee'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/market-stats/market-stats-shipping-fee.html',
                        controller: 'MarketStatsShippingFeeController',
                        controllerAs: 'vm'
                    }
                },

                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('shippingData');
                        $translatePartialLoader.addPart('transaction');
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }]
                }
            })
            .state('market-stats-sales-records', {
                parent: 'entity',
                url: '/sales-records?page&sort',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_MANAGER', 'ROLE_ANALYST', 'ROLE_MARKET_MANAGER'],
                    pageTitle: 'global.menu.stats.salesRecords'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/market-stats/market-stats-sales-records.html',
                        controller: 'MarketStatsSalesRecordsController',
                        controllerAs: 'vm'
                    }
                },
                params: {
                    page: {
                        value: '1',
                        squash: true
                    },
                    sort: {
                        value: 'purchaseDate,desc',
                        squash: true
                    },
                    productName: null,
                    productId: null,
                    clients: ['b2b', 'bozoraka', 'mobpay'],
                    userPhone: null,
                    quantity: null,
                    fromDate: null,
                    toDate: null,
                    firstRun: true
                },
                resolve: {
                    pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                        return {
                            productName: $stateParams.productName,
                            productId: $stateParams.productId,
                            clients: $stateParams.clients,
                            userPhone: $stateParams.userPhone,
                            quantity: $stateParams.quantity,
                            fromDate: $stateParams.fromDate,
                            toDate: $stateParams.toDate,
                            firstRun: $stateParams.firstRun,
                            page: PaginationUtil.parsePage($stateParams.page),
                            sort: $stateParams.sort,
                            predicate: PaginationUtil.parsePredicate($stateParams.sort),
                            ascending: PaginationUtil.parseAscending($stateParams.sort),
                        };
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('statsMerchants');
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }]
                }
            });
    }

})();
