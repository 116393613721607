(function () {
    'use strict';

    angular
        .module('bozorakaAdminApp')
        .controller('OrderInfoDetailController', OrderInfoDetailController);

    OrderInfoDetailController.$inject = ['$state', '$http', '$scope', '$uibModal', '$rootScope', '$translate', 'previousState', 'DateUtils', 'DataUtils', 'entity', 'OrderInfo', 'OrderHistory', 'Courier', 'Delivery', 'DeliveryOrderTracking', 'OrderInfoDelivery', 'AlertService', 'OrderInfoAddressChangeLog'];

    function OrderInfoDetailController($state, $http, $scope, $uibModal, $rootScope, $translate, previousState, DateUtils, DataUtils, entity, OrderInfo, OrderHistory, Courier, Delivery, DeliveryOrderTracking, OrderInfoDelivery, AlertService, OrderInfoAddressChangeLog) {
        var vm = this;

        vm.saveOrderHistory = saveOrderHistory;
        vm.totalPrice = totalPrice;
        vm.saveDelivery = saveDelivery;
        vm.isDeliveryFieldEmpty = isDeliveryFieldEmpty;
        vm.getOrderTracking = getOrderTracking;
        vm.updateOrderTrackings = updateOrderTrackings;
        vm.getCustomerName = getCustomerName;
        vm.generateMarketReceipt = generateMarketReceipt;
        vm.getProductName = getProductName;


        vm.orderInfo = entity;
        vm.addressAndStatusChangeLogs = [];
        vm.products = vm.orderInfo.orderedProducts;
        vm.histories = vm.orderInfo.orderHistories;
        vm.transactions = vm.orderInfo.transaction;
        vm.previousState = previousState.name;
        vm.byteSize = DataUtils.byteSize;
        vm.openFile = DataUtils.openFile;
        vm.dateDiff = DateUtils.transactionDateDifference;
        vm.delivery = {
            id: null,
            courierId: null,
            trackingNumber: null,
            status: null,
            orderInfoId: vm.orderInfo.id
        };
        vm.courierPickUpId = null;
        var headers = {
            lang: $translate.use()
        };

        vm.isOrderCanceled = vm.orderInfo.status === 'CANCELED';
        vm.isOrderShipped = vm.orderInfo.status === 'SHIPPED';
        vm.defaultLanguage = vm.orderInfo.langKey ? vm.orderInfo.langKey : 'en'
        notifyDisabled();
        boxColor();
        getNotificationMessage();
        totalPrice();
        getAllCouriers();
        if (vm.isOrderShipped) {
            getDelivery();
        }
        mergeStatusChangeLogs();

        function mergeStatusChangeLogs() {
            if (vm.orderInfo.orderStatusChangeLog !== undefined) {
                vm.orderInfo.orderStatusChangeLog.forEach(function (item) {
                    vm.addressAndStatusChangeLogs.push({
                        'from': item.fromStatus,
                        'to': item.toStatus,
                        'modifiedBy': item.modifiedBy,
                        'modifiedDate': item.modifiedDate
                    });
                });
            }
        }

        OrderInfoAddressChangeLog.get({id: vm.orderInfo.id}, function (data) {
            vm.addressChangeLogs = data;
            mergeAddressChangeLogs();
        });

        function mergeAddressChangeLogs() {
            vm.addressChangeLogs.forEach(function (item) {
                vm.addressAndStatusChangeLogs.push({
                    'from': item.fromAddress,
                    'to': item.toAddress,
                    'modifiedBy': item.modifiedBy,
                    'modifiedDate': item.modifiedDate
                });
            });
            vm.addressAndStatusChangeLogs.sort(function (a, b) {
                var dateA = new Date(a.modifiedDate), dateB = new Date(b.modifiedDate);
                return dateA-dateB;
            });
        }

        setTimeout(function () {
            initSlimScroll('.scroller');
        }, 0);

        function initSlimScroll(el) {
            if (!$().slimScroll) {
                return;
            }

            $(el).each(function () {
                if ($(this).attr("data-initialized")) {
                    return; // exit
                }

                var height;

                if ($(this).attr("data-height")) {
                    height = $(this).attr("data-height");
                } else {
                    height = $(this).css('height');
                }

                $(this).slimScroll({
                    allowPageScroll: true, // allow page scroll when the element scroll is ended
                    size: '7px',
                    color: ($(this).attr("data-handle-color") ? $(this).attr("data-handle-color") : '#bbb'),
                    wrapperClass: ($(this).attr("data-wrapper-class") ? $(this).attr("data-wrapper-class") : 'slimScrollDiv'),
                    railColor: ($(this).attr("data-rail-color") ? $(this).attr("data-rail-color") : '#eaeaea'),
                    position: 'right',
                    height: height,
                    alwaysVisible: ($(this).attr("data-always-visible") == "1" ? true : false),
                    railVisible: ($(this).attr("data-rail-visible") == "1" ? true : false),
                    disableFadeOut: true
                });

                $(this).attr("data-initialized", "1");
            });
        }

        var unsubscribe = $rootScope.$on('bozorakaAdminApp:orderInfoUpdate', function (event, result) {
            vm.orderInfo = result;

        });
        $scope.$on('$destroy', unsubscribe);

        setPageTitle();

        $rootScope.$on('$translateChangeSuccess', function () {
            setPageTitle();
        });

        function setPageTitle() {
            $translate('orderInfo.detail.title').then(function (title) {
                window.document.title = title + vm.orderInfo.id;
            });
        }

        function saveOrderHistory() {
            var orderHistory = {
                orderInfoId: vm.orderInfo.id,
                message: vm.orderHistory.message,
                notifyUser: vm.orderHistory.notifyUser
            };
            vm.isSaving = true;
            if (vm.orderHistory.id !== null) {
                OrderHistory.update(orderHistory, onSaveSuccessOrderHistory, onSaveErrorOrderHistory);
            } else {
                OrderHistory.save(orderHistory, onSaveSuccessOrderHistory, onSaveErrorOrderHistory);
            }
        }

        function onSaveSuccessOrderHistory(result) {
            vm.isSaving = false;
            getOrderInfo();
        }

        function onSaveErrorOrderHistory() {
            vm.isSaving = false;
        }

        function boxColor() {
            if (vm.transactions != undefined) {
                var status = vm.transactions.status;
                if (status == 'SUCCESS') {
                    vm.statusColor = 'green-seagreen';
                } else if (status == 'FAILURE') {
                    vm.statusColor = 'red-mint';
                } else if (status == 'INPROGRESS') {
                    vm.statusColor = 'yellow-saffron';
                } else if (status == 'WAITING_FOR_PAYMENT') {
                    vm.statusColor = 'yellow-casablanca';
                }
            }
        }

        function getNotificationMessage() {
            if (vm.orderInfo.langKey == 'ru') {
                vm.messageInUserLang = 'Russian';
            } else if (vm.orderInfo.langKey == 'en') {
                vm.messageInUserLang = 'English';
            } else if (vm.orderInfo.langKey == 'uz') {
                vm.messageInUserLang = 'Uzbek';
            }
        }

        function notifyDisabled() {
            if (vm.isOrderShipped) {
                vm.notify = true;
            } else {
                vm.notify = false;
            }
        }

        function totalPrice() {
            var totalPrice = 0;
            angular.forEach(vm.products, function (value, key) {
                totalPrice += value.totalPrice;
            });
            return totalPrice;
        }

        vm.orderInfoAddress = function () {
            $uibModal.open({
                templateUrl: 'app/entities/order-info/order-info-address-dialog.html',
                controller: 'OrderInfoStatusChangeDialogController',
                controllerAs: 'vm',
                size: 'lg',
                resolve: {
                    entity: vm.orderInfo
                }
            }).result.then(function () {
                $state.go('order-info-detail', {}, {reload: true});
            }, function () {

            });
        };

        vm.orderInfoCloudAddress = function () {
            $uibModal.open({
                templateUrl: 'app/entities/order-info/order-info-cloud-address-dialog.html',
                controller: 'OrderInfoStatusChangeDialogController',
                controllerAs: 'vm',
                size: 'lg',
                resolve: {
                    entity: vm.orderInfo
                }
            }).result.then(function () {
                $state.go('order-info-detail', {}, {reload: true});
            }, function () {

            });
        };

        vm.orderCustomerInfo = function () {
            $uibModal.open({
                templateUrl: 'app/entities/order-info/order-customer-info-dialog.html',
                controller: 'OrderCustomerInfoDialog',
                controllerAs: 'vm',
                size: 'lg',
                resolve: {
                    entity: vm.orderInfo
                }
            }).result.then(function () {
                $state.go('order-info-detail', {}, {reload: true});
            }, function () {

            });
        };

        function getAllCouriers() {
            Courier.query({
                page: 0,
                size: 1000,
                sort: null
            }, onSuccess, onError);

            function onSuccess(data) {
                vm.couriers = data;
                setCourierPickUpId();
            }

            function onError(error) {
                AlertService.error(error.data.message);
            }
        }

        function setCourierPickUpId() {
            var length = vm.couriers.length,
                courier = null;

            for (var i = 0; i < length; i++) {
                courier = vm.couriers[i];
                if (courier.code === "00") {
                    vm.courierPickUpId = courier.id;
                }
            }
        }

        function saveDelivery() {
            vm.isSavingDelivery = true;
            if (vm.isOrderShipped) {
                vm.delivery['customerId'] = vm.transactions.profile.user.id;
                if (vm.delivery.id !== null) {
                    Delivery.update(vm.delivery, onSaveSuccessDelivery, onSaveErrorDelivery);
                } else {
                    Delivery.save(vm.delivery, onSaveSuccessDelivery, onSaveErrorDelivery);
                }
            }
        }

        function onSaveSuccessDelivery(data) {
            vm.isSavingDelivery = false;
            vm.delivery = data;
            getOrderInfo();
            AlertService.clear();
            AlertService.success("The delivery data has been saved");
            vm.alerts = AlertService.get();
            $scope.$on('$destroy', function () {
                vm.alerts = [];
            });
        }

        function onSaveErrorDelivery(error) {
            vm.isSavingDelivery = false;
            AlertService.clear();
            AlertService.error("Delivery data didn't change");
            vm.alerts = AlertService.get();
            $scope.$on('$destroy', function () {
                vm.alerts = [];
            });
        }

        function getDelivery() {
            OrderInfoDelivery.get({id: vm.orderInfo.id}, function (data) {
                vm.delivery = data;
                getOrderTracking();
            });
        }

        function getOrderTracking() {
            vm.orderTrackings = [];
            if (vm.delivery.id !== null) {
                DeliveryOrderTracking.query({id: vm.delivery.id}, function (data) {
                    vm.orderTrackings = data;
                })
            }
        }

        function isDeliveryFieldEmpty() {
            return vm.delivery.courierId != vm.courierPickUpId && (vm.delivery.courierId === null || vm.delivery.trackingNumber === null
                || vm.delivery.courierId.length === 0 || vm.delivery.trackingNumber.length === 0);
        }

        function updateOrderTrackings() {
            if (vm.orderInfo.id !== null) {
                OrderInfoDelivery.update({id: vm.orderInfo.id}, function (data) {
                    vm.delivery = data;
                    getOrderTracking();
                });
            }
        }

        function getCustomerName() {
            if (vm.orderInfo.recipientName !== null) {
                return vm.orderInfo.recipientName;
            }
            return vm.orderInfo.transaction.profile.user.fullName;
        }

        function getOrderInfo() {
            OrderInfo(headers).get({id: vm.orderInfo.id}, function (data) {
                vm.orderInfo = data;
                vm.histories = vm.orderInfo.orderHistories;
                vm.orderHistory.message = null;
                if (vm.isOrderShipped) {
                    vm.notify = true;
                    vm.orderHistory.notifyUser = true;
                } else {
                    vm.notify = false;
                    vm.orderHistory.notifyUser = false;
                }

            });
        }

        function generateMarketReceipt(id, language) {
            $http({
                url: 'api/order-infos/' + id + '/market-receipt?invoiceLang=' + language,
                method: 'GET',
                responseType: 'arraybuffer'
            }).success(function (response) {
                var blob = new Blob([response], {
                    type: 'application/pdf'
                });
                saveAs(blob, 'market-receipt_' + vm.transactions.requestNo + '.pdf');
            }).error(function () {
            });
        }

        function getProductName(product) {
            var lang = $translate.use();
            var name = product.productNameUzb;
            if (lang === 'en' && product.productNameEng !== null) {
                name = product.productNameEng;
            }
            return name;
        }
    }
})();
