(function() {
    'use strict';

    angular
        .module('bozorakaAdminApp')
        .controller('PartnerDetailController', PartnerDetailController);

    PartnerDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'AlertService', 'Partner', 'Tier', 'Agent', 'DataUtils'];

    function PartnerDetailController($scope, $rootScope, $stateParams, previousState, entity, AlertService, Partner, Tier, Agent, DataUtils) {
        var vm = this;
        vm.partner = entity;
        vm.partnerLogin = vm.partner.login;
        vm.previousState = previousState.name;
        vm.isAgent = isAgent;
        vm.isSuperAgent = vm.partner.authorityName === "ROLE_SUPER_AGENT";
        vm.save = save;
        vm.changePassword = changePassword;
        vm.bankName = DataUtils.getBankNameByIndex(vm.partner.bankId);
        vm.tiers = Tier.query();

        var numberPattern = /\d+/g;
        vm.newBusinessNumber = vm.partner.businessRegistrationNumber;
        vm.formatBusinessNumber = formatBusinessNumber;

        function formatBusinessNumber() {
            var numbers = vm.newBusinessNumber.match(numberPattern);
            if (numbers === null) {
                vm.newBusinessNumber = null;
                return 0
            }
            vm.newBusinessNumber = numbers.join([]).replace(/(\d{3})(\d{2})(\d+)/g, '$1-$2-$3');
        }

        function save () {
            vm.isSaving = true;
            vm.partner.businessRegistrationNumber = vm.newBusinessNumber;
            if (isAgent()) {
                vm.partner.authorityName = vm.isSuperAgent ? "ROLE_SUPER_AGENT" : "ROLE_AGENT";
                if (vm.partner.id !== null) {
                    Agent.update(vm.partner, onSaveSuccess, onSaveError);
                } else {
                    Agent.save(vm.partner, onSaveSuccess, onSaveError);
                }
            } else {
                if (vm.partner.id !== null) {
                    Partner.update(vm.partner, onSaveSuccess, onSaveError);
                } else {
                    Partner.save(vm.partner, onSaveSuccess, onSaveError);
                }
            }

        }

        function onSaveSuccess (result) {
            $scope.$emit('bozorakaAdminApp:partnerUpdate', result);
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }

        var unsubscribe = $rootScope.$on('bozorakaAdminApp:partnerUpdate', function(event, result) {
            vm.partner = result;
            vm.bankName = DataUtils.getBankNameByIndex(vm.partner.bankId);
        });
        $scope.$on('$destroy', unsubscribe);

        function changePassword() {
            vm.isSaving = true;
            Partner.changePassword({id: vm.partner.id, newPassword: vm.newPassword}, function (data) {
                vm.newPassword = null;
                vm.isSaving = false;
                AlertService.success('Password updated');
            }, function () {
                vm.isSaving = false;
                AlertService.error('Password update error');
            })
        }

        function isAgent() {
            return vm.partner.authorityName !== 'ROLE_PARTNER';
        }
    }
})();
