(function() {
    'use strict';

    angular
        .module('bozorakaAdminApp')
        .controller('FlashSaleFormDialogController', FlashSaleFormDialogController);

    FlashSaleFormDialogController.$inject = ['$uibModalInstance', 'AlertService', 'FlashSale', 'selectedProduct', 'variants'];

    function FlashSaleFormDialogController($uibModalInstance, AlertService, FlashSale, selectedProduct, variants) {
        var vm = this;

        vm.selectedProduct = selectedProduct;
        vm.variants = variants;
        vm.flashSaleData = {
            customerType: "B2C"
        };
        vm.calculateSalePrice = calculateSalePrice;
        vm.saveFlashSale = saveFlashSale;
        vm.datePickerOpenStatus = {};
        vm.validateDates = validateDates;
        vm.openCalendar = openCalendar;
        function openCalendar(date) {
            vm.datePickerOpenStatus[date] = true;
        }

         function validateDates() {
            vm.dateError = null;

            if (vm.flashSaleData.startTime && vm.flashSaleData.endTime) {
                if (vm.flashSaleData.startTime > vm.flashSaleData.endTime) {
                    vm.dateError = "Start date cannot be after the end date.";
                } else if (vm.flashSaleData.endTime < vm.flashSaleData.startTime) {
                    vm.dateError = "End date cannot be before the start date.";
                }
            }
        }

        function saveFlashSale() {
            if (vm.flashSaleData.startTime && vm.flashSaleData.endTime && vm.flashSaleData.discountPercentage) {
                var flashSale = {
                    productId: vm.selectedProduct.productId,
                    customerType: vm.flashSaleData.customerType,
                    startTime: vm.flashSaleData.startTime,
                    endTime: vm.flashSaleData.endTime,
                    discountPercentage: vm.flashSaleData.discountPercentage,
                    visibleState: "ACTIVE",
                    status: "UPCOMING"
                };

                FlashSale.save(flashSale, onSaveSuccess, onSaveError);

                function onSaveSuccess() {
                    $uibModalInstance.close({ success: true });
                }

                function onSaveError() {

                }
            }
        }

        function calculateSalePrice() {
            if (vm.selectedProduct.retailPrice && vm.flashSaleData.discountPercentage) {
                return vm.selectedProduct.retailPrice - (vm.selectedProduct.retailPrice * (vm.flashSaleData.discountPercentage / 100));
            } else {
                return 0;
            }
        }

        vm.close = function() {
            $uibModalInstance.dismiss('cancel');
        };
    }
})();
