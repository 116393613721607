(function () {
    'use strict';
    angular
        .module('bozorakaAdminApp')
        .factory('MarketStatsSalesRecords', MarketStatsSalesRecords)
        .factory('MarketStatsSalesRecordsTotals', MarketStatsSalesRecordsTotals);

    MarketStatsSalesRecords.$inject = ['$resource'];

    function MarketStatsSalesRecords($resource) {
        var resourceUrl = 'api/market-stats/sales-records';

        return $resource(resourceUrl, {}, {
            'query': {method: 'GET', isArray: true}
        });
    }

    MarketStatsSalesRecordsTotals.$inject = ['$resource'];

    function MarketStatsSalesRecordsTotals($resource) {
        var resourceUrl = 'api/market-stats/sales-records/totals';

        return $resource(resourceUrl, {}, {
            'query': {method: 'GET'}
        });
    }
})();
