(function () {
    'use strict';

    angular
        .module('bozorakaAdminApp')
        .controller('ProductPagePreviewController', ProductPagePreviewController);

    ProductPagePreviewController.$inject = ['pagingParams', 'paginationConstants', 'ParseLinks', '$state', 'ProductStorage', 'ProductCategory', 'Category', 'CategoryProductSort'];

    function ProductPagePreviewController(pagingParams, paginationConstants, ParseLinks, $state, ProductStorage, ProductCategory, Category, CategoryProductSort) {
        var vm = this;

        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.itemsPerPage = paginationConstants.itemsPerPage;
        vm.lang = pagingParams.langKey;
        vm.customerType = pagingParams.customerType;
        vm.save = save;
        vm.clearProduct = clearProduct;
        vm.selectedProduct = ProductStorage.getProduct();
        vm.categoryId = pagingParams.categoryId;
        var replacedProduct = null;
        vm.onCustomerTypeChange = onCustomerTypeChange;

        loadCategory();

        function loadAll() {
            ProductCategory.getAsList({
                categoryId: vm.categoryId,
                langKey: vm.lang,
                customerType: vm.customerType
            }, onSuccess, onError);

            return null;
        }

        function onSuccess(data) {
            vm.products = data;
        }

        function onError(error) {
            AlertService.error(error.data.message);
        }

        vm.onChange = function () {
            loadAll();
            clearProduct();
        };

        vm.onChangeLanguage = function () {
            loadAll();
            clearProduct();
        };

        function loadCategory() {
            Category.query(onSuccess);

            function onSuccess(data) {
                vm.categorys = data;
                if (vm.categoryId == null) {
                    clearProduct();
                    vm.categoryId = vm.categorys[0].id;
                }
                loadAll();
            }
        }

        Sortable.create(product, {
            ghostClass: 'ghost',
            sort: true
        });

        function save() {
            var newSortingOrder = makeProductList();
            CategoryProductSort.update(newSortingOrder, saveSuccess);

            function saveSuccess() {
                loadAll();
            }
        }

        function makeProductList() {
            var newSortingOrder = [];
            var dataList = $("#product .item-pro-ajax").map(function () {
                return $(this).data("id");
            }).get();
            for (var i = 0; i < dataList.length; i++) {
                newSortingOrder[i] = {
                    categoryId: vm.categoryId,
                    productId: dataList[i],
                    customerType: vm.customerType,
                    lang: vm.lang,
                    sortOrder: i
                };
            }
            return newSortingOrder;
        }

        vm.replace = function (product, replacedProductIndex) {
            if (!angular.isUndefined(vm.selectedProductIndex)) {
                vm.products[vm.selectedProductIndex] = product;
                vm.products[replacedProductIndex] = vm.selectedProduct;
            } else {
                vm.products[replacedProductIndex] = vm.selectedProduct;
                replacedProduct = product;
            }
            clearProduct();
        };

        vm.insert = function (product, insertedProductIndex) {
            var selectedProductSortOrderIndex = findIndexByProductId(vm.selectedProduct.id);
            var insertedProductSortOrderIndex = findIndexByProductId(vm.products[insertedProductIndex].id);
            if (insertedProductSortOrderIndex > selectedProductSortOrderIndex) {
                var beginIndex = 0;
                if (!angular.isUndefined(vm.selectedProductIndex)) {
                    beginIndex = vm.selectedProductIndex;
                }
                for (var i = beginIndex; i < insertedProductIndex; i++) {
                    vm.products[i] = vm.products[i + 1];
                }
                vm.products[insertedProductIndex] = vm.selectedProduct;
            } else {
                var beginIndex = vm.products.length;
                if (!angular.isUndefined(vm.selectedProductIndex)) {
                    beginIndex = vm.selectedProductIndex;
                }
                for (var i = beginIndex; i > insertedProductIndex; i--) {
                    vm.products[i] = vm.products[i - 1];
                }
                vm.products[insertedProductIndex] = vm.selectedProduct;
            }
            clearProduct();
        };

        vm.select = function (product, index) {
            ProductStorage.setProduct(product);
            vm.selectedProductIndex = index;
            vm.selectedProduct = ProductStorage.getProduct();
        };

        function clearProduct() {
            ProductStorage.setProduct(null);
            vm.selectedProduct = ProductStorage.getProduct();
        }

        function findIndexByProductId(productId) {
            var dataList = $("#product .item-pro-ajax").map(function () {
                return $(this).data("id");
            }).get();

            for (var i = 0; i < dataList.length; i++) {
                if (dataList[i] === productId) {
                    return i;
                }
            }
        }

        function onCustomerTypeChange() {
            clearProduct();
            loadAll();
        }
    }
})();
