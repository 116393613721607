(function () {
    'use strict';

    angular
        .module('bozorakaAdminApp')
        .controller('MarketStatsSalesRecordsController', MarketStatsSalesRecordsController);

    MarketStatsSalesRecordsController.$inject = ['$filter', '$http', '$state', 'DateUtils', 'AlertService', 'DataUtils', 'MarketStatsSalesRecords', 'MarketStatsSalesRecordsTotals', 'ParseLinks', 'paginationConstants', 'pagingParams'];

    function MarketStatsSalesRecordsController($filter, $http, $state, DateUtils, AlertService, DataUtils, MarketStatsSalesRecords, MarketStatsSalesRecordsTotals, ParseLinks, paginationConstants, pagingParams) {
        var vm = this;
        var translate = $filter('translate'), all, noneSelected, allSelected;

        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.transition = transition;
        vm.itemsPerPage = paginationConstants.itemsPerPage;
        vm.clear = clear;
        vm.search = search;
        vm.exportExcel = exportExcel;
        vm.searchQuery = {
            productName: pagingParams.productName,
            productId: pagingParams.productId,
            clients: pagingParams.clients,
            userPhone: pagingParams.userPhone,
            quantity: pagingParams.quantity,
            fromDate: pagingParams.fromDate,
            toDate: pagingParams.toDate,
        };

        initDatePicker();

        if (!pagingParams.firstRun) {
            loadAll();
        }

        function transition() {
            $state.transitionTo($state.$current, {
                page: vm.page,
                sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc'),
                productName: vm.searchQuery.productName,
                productId: vm.searchQuery.productId,
                quantity: vm.searchQuery.quantity,
                userPhone: vm.searchQuery.userPhone,
                clients: vm.searchQuery.clients,
                fromDate: vm.searchQuery.fromDate,
                toDate: vm.searchQuery.toDate,
                firstRun: false
            });
        }

        function clear() {
            vm.links = null;
            vm.page = 1;
            vm.predicate = 'purchaseDate';
            vm.reverse = true;
            vm.fromDate = DateUtils.currentDateFirstMonth();
            vm.toDate = DateUtils.currentDateFirstMonth();
            vm.searchQuery = {
                productName: null,
                productId: null,
                clients: ['b2b', 'bozoraka', 'mobpay'],
                userPhone: null,
                quantity: null,
                fromDate: moment(vm.fromDate).subtract(1, "months").format('MM/DD/YYYY'),
                toDate: moment(vm.toDate).format('MM/DD/YYYY'),
            };
            initDatePicker();
            $('#customerClient').multiselect('selectAll', false);
            $('#customerClient').multiselect('refresh');
        }

        setTimeout(function () {
            translateMultiSelect();

            $('#customerClient').multiselect({
                buttonClass: "mt-multiselect btn btn-default",
                includeSelectAllOption: true,
                dropRight: false,
                buttonWidth: '100%',
                selectAllText: translate('statistics.all'),
                nonSelectedText: translate('statistics.noneSelected'),
                allSelectedText: translate('statistics.allSelected')
            });

        }, 0);

        function search() {
            $('.input-daterange input').each(function () {
                if ($(this).attr('name') == 'from') {
                    vm.searchQuery.fromDate = $(this).val();
                } else if ($(this).attr('name') == 'to') {
                    vm.searchQuery.toDate = $(this).val();
                }
            });

            vm.data = [];
            vm.links = null;
            vm.page = 1;
            vm.predicate = 'purchaseDate';
            vm.reverse = false;
            vm.firstRun = false;
            vm.transition();
        }

        function loadAll() {
            if (vm.searchQuery.fromDate == null || vm.searchQuery.toDate == null) {
                return
            }
            if(vm.searchQuery.userPhone !== null){
                vm.userPhone = vm.searchQuery.userPhone.replace(/[\s-]/g, '');
            }
            MarketStatsSalesRecords.query({
                page: pagingParams.page - 1,
                size: vm.itemsPerPage,
                sort: sort(),
                fromDate: moment(vm.searchQuery.fromDate).format("YYYY-MM-DD"),
                toDate: moment(vm.searchQuery.toDate).format("YYYY-MM-DD"),
                clients: vm.searchQuery.clients,
                productName: vm.searchQuery.productName,
                productId: vm.searchQuery.productId,
                userPhone: vm.userPhone,
                quantity: vm.searchQuery.quantity,
            }, onSuccess, onError);

            MarketStatsSalesRecordsTotals.query({
                fromDate: moment(vm.searchQuery.fromDate).format("YYYY-MM-DD"),
                toDate: moment(vm.searchQuery.toDate).format("YYYY-MM-DD"),
                clients: vm.searchQuery.clients,
                productName: vm.searchQuery.productName,
                productId: vm.searchQuery.productId,
                userPhone: vm.userPhone,
                quantity: vm.searchQuery.quantity,
            }, success);
        }

        function sort() {
            var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
            if (vm.predicate !== 'purchaseDate') {
                result.push('purchaseDate');
            }
            return result;
        }

        function onSuccess(data, headers) {
            vm.links = ParseLinks.parse(headers('link'));
            vm.totalItems = headers('X-Total-Count');
            vm.queryCount = vm.totalItems;
            vm.records = data;
            vm.page = pagingParams.page;
        }

        function onError(error) {
            console.error(error);
            AlertService.error(error.data.message);
        }

        function success(data) {
            vm.totalQuantity = data.totalQuantity;
            vm.totalAmount = data.totalAmount;
        }

        function translateMultiSelect() {
            all = translate('statistics.all');
            noneSelected = translate('statistics.noneSelected');
            allSelected = translate('statistics.allSelected');
        }

        function initDatePicker() {
            if (vm.searchQuery.toDate === null) {
                vm.searchQuery.toDate = moment().format('MM/DD/YYYY');
                pagingParams.toDate = vm.searchQuery.toDate;
            }
            if (vm.searchQuery.fromDate === null) {
                vm.searchQuery.fromDate = moment(vm.searchQuery.toDate, 'MM/DD/YYYY').subtract(1, "months").format('MM/DD/YYYY');
                pagingParams.fromDate = vm.searchQuery.fromDate;
            }
            $('.input-daterange input').each(function () {
                if ($(this).attr('name') === 'from') {
                    $(this).val(vm.searchQuery.fromDate);
                } else if ($(this).attr('name') === 'to') {
                    $(this).val(vm.searchQuery.toDate);
                }
                $(this).datepicker('update');
            });

            $('.date-picker').datepicker({autoclose: true});
        }

        function exportExcel() {
            if(vm.searchQuery.userPhone !== null){
                vm.userPhone = vm.searchQuery.userPhone.replace(/[\s-]/g, '');
            }
            var data = "fromDate=" + moment(vm.searchQuery.fromDate).format("YYYY-MM-DD") + "&toDate=" + moment(vm.searchQuery.toDate).format("YYYY-MM-DD") + "&clients=" + (vm.searchQuery.clients.length === 0 ? [] : vm.searchQuery.clients) + "&productName=" + (vm.searchQuery.productName === null ? 'UNKNOWN' : vm.searchQuery.productName) + (vm.searchQuery.productId === null ? "" : "&productId=" + vm.searchQuery.productId) + "&userPhone=" + (vm.searchQuery.userPhone === null ? "" : vm.userPhone) + (vm.searchQuery.quantity === null ? "" : "&quantity=" + vm.searchQuery.quantity);
            $http({
                url: 'api/market-stats/sales-records/excel',
                method: 'POST',
                responseType: 'arraybuffer',
                data: data,
                headers: {
                    'Content-type': 'application/x-www-form-urlencoded'
                }
            }).success(function (data) {
                var blob = new Blob([data], {
                    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
                });
                saveAs(blob, 'sales-records-' + DateUtils.currentTimestamp() + '.xlsx');
            }).error(function () {
            });
        }

        Array.prototype.sum = function (prop) {
            var total = 0;
            for (var i = 0, _len = this.length; i < _len; i++) {
                total += this[i][prop];
            }
            return total;
        };
    }
})();
