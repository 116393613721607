(function() {
    'use strict';

    angular
        .module('bozorakaAdminApp')
        .controller('PartnerOrderInfoDetailController', PartnerOrderInfoDetailController);

    PartnerOrderInfoDetailController.$inject = ['$scope', '$http', '$uibModal', 'DateUtils', '$rootScope', '$stateParams', '$state', 'previousState', 'entity', 'AlertService', 'PartnerOrderNotice', 'PartnerOrderStatusChangeLog', 'Courier', 'Delivery', 'PartnerOrderInfo', 'PartnerOrderInfoDelivery', 'DeliveryOrderTracking', 'PartnerOrderInfoAddressChangeLog'];

    function PartnerOrderInfoDetailController($scope, $http, $uibModal, DateUtils, $rootScope, $stateParams, $state, previousState, entity, AlertService, PartnerOrderNotice, PartnerOrderStatusChangeLog, Courier, Delivery, PartnerOrderInfo, PartnerOrderInfoDelivery, DeliveryOrderTracking, PartnerOrderInfoAddressChangeLog) {
        var vm = this;

        vm.partnerOrderInfo = entity;
        vm.isAgent = isAgent;
        vm.statusChangeLogs = vm.partnerOrderInfo.statusChangeLogs;
        vm.productList = vm.partnerOrderInfo.orderedProducts;
        vm.noticeList = vm.partnerOrderInfo.noticeList;
        vm.newStatus = vm.partnerOrderInfo.status;
        vm.dateDiff = DateUtils.partnerOrderDateDifference;
        vm.previousState = previousState.name;
        vm.newPaymentStatus = vm.partnerOrderInfo.paymentStatus;
        vm.changeStatus = changeStatus;
        vm.isStatusChangeable = isStatusChangeable;
        vm.addMessage = addMessage;
        vm.isDeliveryFieldEmpty = isDeliveryFieldEmpty;
        vm.saveDelivery = saveDelivery;
        vm.updateOrderTrackingLogs = updateOrderTrackingLogs;
        vm.delivery = {
            id: null,
            courierId: null,
            trackingNumber: null,
            status: null,
            partnerOrderInfoId: vm.partnerOrderInfo.id
        };
        vm.courierPickUpId = null;
        vm.generateOrderInvoice = generateOrderInvoice;
        vm.defaultLanguage = vm.partnerOrderInfo.langKey ? vm.partnerOrderInfo.langKey : 'en'
        mergeChangeLogs();

        function mergeChangeLogs() {
            vm.addressAndStatusChangeLogs = [];
            mergeStatusChangeLogs();

            function mergeStatusChangeLogs() {
                if (vm.statusChangeLogs !== undefined) {
                    vm.statusChangeLogs.forEach(function (item) {
                        vm.addressAndStatusChangeLogs.push({
                            'from': item.fromStatus,
                            'to': item.toStatus,
                            'modifiedBy': item.modifiedBy,
                            'modifiedDate': item.modifiedDate
                        });
                    });
                }
            }

            PartnerOrderInfoAddressChangeLog.get({id: vm.partnerOrderInfo.id}, function (data) {
                vm.addressChangeLogs = data;
                mergeAddressChangeLogs();
            });

            function mergeAddressChangeLogs() {
                vm.addressChangeLogs.forEach(function (item) {
                    vm.addressAndStatusChangeLogs.push({
                        'from': item.fromAddress,
                        'to': item.toAddress,
                        'modifiedBy': item.modifiedBy,
                        'modifiedDate': item.modifiedDate
                    });
                });
                vm.addressAndStatusChangeLogs.sort(function (a, b) {
                    var dateA = new Date(a.modifiedDate), dateB = new Date(b.modifiedDate);
                    return dateA-dateB;
                });
            }
        }

        getAllCouriers();
        function getAllCouriers() {
            Courier.query({
                page: 0,
                size: 1000,
                sort: null
            }, onSuccess, onError);

            function onSuccess(data) {
                vm.couriers = data;
                setCourierPickUpId();
            }

            function onError(error) {
                AlertService.error(error.data.message);
            }
        }

        function setCourierPickUpId() {
            var length = vm.couriers.length,
                courier = null;

            for (var i = 0; i < length; i++) {
                courier = vm.couriers[i];
                if (courier.code === "00") {
                    vm.courierPickUpId = courier.id;
                }
            }
        }

        getDelivery();
        function getDelivery() {
            PartnerOrderInfoDelivery.get({id: vm.partnerOrderInfo.id}, function (data) {
                vm.delivery = data;
                getOrderTracking();
            });
        }

        function updateOrderTrackingLogs() {
            if (vm.partnerOrderInfo.id !== null) {
                PartnerOrderInfoDelivery.update({id: vm.partnerOrderInfo.id}, function (data) {
                    vm.delivery = data;
                    getOrderTracking();
                });
            }
        }

        function getOrderTracking() {
            vm.orderTrackings = [];
            if (vm.delivery.id !== null) {
                DeliveryOrderTracking.query({id: vm.delivery.id}, function (data) {
                    vm.orderTrackings = data;
                })
            }
        }

        setTimeout(function () {
            initSlimScroll('.scroller');
        }, 0);

        function initSlimScroll(el) {
            if (!$().slimScroll) {
                return;
            }

            $(el).each(function () {
                if ($(this).attr("data-initialized")) {
                    return; // exit
                }

                var height;

                if ($(this).attr("data-height")) {
                    height = $(this).attr("data-height");
                } else {
                    height = $(this).css('height');
                }

                $(this).slimScroll({
                    allowPageScroll: true, // allow page scroll when the element scroll is ended
                    size: '7px',
                    color: ($(this).attr("data-handle-color") ? $(this).attr("data-handle-color") : '#bbb'),
                    wrapperClass: ($(this).attr("data-wrapper-class") ? $(this).attr("data-wrapper-class") : 'slimScrollDiv'),
                    railColor: ($(this).attr("data-rail-color") ? $(this).attr("data-rail-color") : '#eaeaea'),
                    position: 'right',
                    height: height,
                    alwaysVisible: ($(this).attr("data-always-visible") == "1" ? true : false),
                    railVisible: ($(this).attr("data-rail-visible") == "1" ? true : false),
                    disableFadeOut: true
                });

                $(this).attr("data-initialized", "1");
            });
        }

        initNotify();

        function initNotify() {
            vm.notify = false;
            vm.orderNotification = {
                partnerOrderInfoId: vm.partnerOrderInfo.id,
                notifyUser: false,
                message: null
            };
        }

        function addMessage () {
            vm.isSaving = true;
            if (vm.orderNotification.message !== null && vm.orderNotification.message.length > 0) {
                PartnerOrderNotice.save(vm.orderNotification,
                    function (data) {
                        vm.noticeList.unshift(data);
                        vm.isSaving = false;
                        initNotify();
                    },
                    function () {
                        vm.isSaving = false;
                    });
            }
        }

        boxColor();

        function isStatusChangeable() {
            return !(vm.partnerOrderInfo.status === "CANCELED");
        }

        var unsubscribe = $rootScope.$on('bozorakaAdminApp:partnerOrderInfoUpdate', function(event, result) {
            vm.partnerOrderInfo = result;
            vm.newStatus = vm.partnerOrderInfo.status;
            mergeChangeLogs();
            boxColor();
        });
        $scope.$on('$destroy', unsubscribe);

        function boxColor() {
            vm.statusColor = 'yellow-saffron';
            var status = vm.partnerOrderInfo.status;
            if (status === 'SHIPPED') {
                vm.statusColor = 'green-seagreen';
            } else if (status === 'CANCELED') {
                vm.statusColor = 'red-mint';
            } else if (status === 'REFUND') {
                vm.statusColor = 'purple';
            }
        }

        function changeStatus() {
            vm.isSaving = true;
            var orderStatusInfo = {
                orderInfoId: vm.partnerOrderInfo.id,
                status: vm.newStatus
            };
            PartnerOrderInfo.save(orderStatusInfo,
                function (result) {
                    vm.partnerOrderInfo.status = result.toStatus;
                    boxColor();
                    vm.statusChangeLogs.push(result);
                    vm.isSaving = false;
                },
                function () {
                    vm.isSaving = false;
                }
            );
        }

        function isAgent() {
            return vm.partnerOrderInfo.partnerAuthorityName === 'ROLE_AGENT';
        }

        function isDeliveryFieldEmpty() {
            return vm.delivery.courierId != vm.courierPickUpId && (vm.delivery.courierId === null || vm.delivery.trackingNumber === null
                || vm.delivery.courierId.length === 0 || vm.delivery.trackingNumber.length === 0);
        }

        function saveDelivery() {
            vm.isSavingDelivery = true;
            if (vm.partnerOrderInfo.status === 'SHIPPED') {
                vm.delivery['partnerId'] = vm.partnerOrderInfo.partnerId;
                if (vm.delivery.id !== null) {
                    Delivery.update(vm.delivery, onSaveSuccessDelivery, onSaveErrorDelivery);
                } else {
                    Delivery.save(vm.delivery, onSaveSuccessDelivery, onSaveErrorDelivery);
                }
            }
        }

        function onSaveSuccessDelivery(data) {
            vm.isSavingDelivery = false;
            vm.delivery = data;
            AlertService.clear();
            AlertService.success("The delivery data has been saved");
            vm.alerts = AlertService.get();
            $scope.$on('$destroy', function () {
                vm.alerts = [];
            });
            $state.reload();
        }

        function onSaveErrorDelivery(error) {
            vm.isSavingDelivery = false;
            AlertService.clear();
            AlertService.error("Delivery data didn't change");
            vm.alerts = AlertService.get();
            $scope.$on('$destroy', function () {
                vm.alerts = [];
            });
        }

        vm.zoomInAddressImage = function () {
            vm.orderInfo = {
                addressSnapshot: vm.partnerOrderInfo.addressImageUrl
            };
            $uibModal.open({
                templateUrl: 'app/entities/order-info/order-info-cloud-address-dialog.html',
                controller: 'OrderInfoStatusChangeDialogController',
                controllerAs: 'vm',
                size: 'lg',
                resolve: {
                    entity: vm.orderInfo
                }
            }).result.then(function () {
                $state.go('^', {}, {reload: true});
            }, function () {

            });
        };

        function generateOrderInvoice(id, language) {
            $http({
                url: 'api/partner-order-infos/' + id + '/order-invoice?invoiceLang=' + language,
                method: 'GET',
                responseType: 'arraybuffer',
            }).success(function (response) {
                var blob = new Blob([response], {
                    type: 'application/pdf'
                });
                saveAs(blob, 'order-invoice_' + vm.partnerOrderInfo.id + '.pdf');
            }).error(function () {
            });
        }
    }
})();
