(function() {
    'use strict';

    angular
        .module('bozorakaAdminApp')
        .controller('FlashSaleHistoryController', FlashSaleHistoryController);

    FlashSaleHistoryController.$inject = ['$state', 'FlashSaleHistory', 'ParseLinks', 'AlertService', '$stateParams'];

    function FlashSaleHistoryController($state, FlashSaleHistory, ParseLinks, AlertService, $stateParams) {

        var vm = this;
        vm.id = $stateParams.id;
        vm.name = $stateParams.name;
        vm.sku = $stateParams.sku;
        vm.loadAll = loadAll;
        vm.hasSku = hasSku;

        loadAll();

        function loadAll() {
            FlashSaleHistory.query({
                id: vm.id,
            }, onSuccess, onError);

            function onSuccess(data) {
                vm.flashSaleHistories = data.flashSaleHistories;
                vm.totalSold = data.totalSold;
            }
            function onError(error) {
                AlertService.error(error.data.message);
            }
        }

        function hasSku() {
            return vm.sku !== null && vm.sku !== undefined && vm.sku !== "";
        }
    }
})();
