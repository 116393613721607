(function () {
    'use strict';

    angular
        .module('bozorakaAdminApp')
        .controller('InventoryController', InventoryController);

    InventoryController.$inject = ['$state', '$http', 'Principal', 'ProductVariant', 'DepoService', 'Product', 'Category', 'PurchasingAgent', 'ParseLinks', 'AlertService', 'paginationConstants', 'pagingParams', '$translate', '$rootScope', 'ProductInventory', 'DateUtils', '$filter', '$uibModal', 'Vendor'];

    function InventoryController($state, $http, Principal, ProductVariant, DepoService, Product, Category, PurchasingAgent, ParseLinks, AlertService, paginationConstants, pagingParams, $translate, $rootScope, ProductInventory, DateUtils, $filter, $uibModal, Vendor) {
        var vm = this;
        var translate = $filter('translate'), all, noneSelected, allSelected;

        vm.loadPage = loadPage;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.transition = transition;
        vm.itemsPerPage = paginationConstants.itemsPerPage;
        vm.clear = clear;
        vm.isAccess = isAccess;
        vm.isAdmin = isAdmin();
        vm.isManager = isManager();
        vm.search = search;
        vm.excel = excel;
        vm.loadAll = loadAll;
        vm.saveProductStatus = saveProductStatus;
        vm.saveProductPurchasingAgent = saveProductPurchasingAgent;
        vm.saveProductSku = saveProductSku;
        vm.saveProductVariantSku = saveProductVariantSku;
        vm.saveProductBarcode = saveProductBarcode;
        vm.saveProductVariantBarcode = saveProductVariantBarcode;
        vm.saveProductShippingTag = saveProductShippingTag;
        vm.saveProductVariantShippingTag = saveProductVariantShippingTag;
        vm.saveProductPurchasePrice = saveProductPurchasePrice;
        vm.saveProductVariantPurchasePrice = saveProductVariantPurchasePrice;
        vm.saveProductMinInventoryQuantity = saveProductMinInventoryQuantity;
        vm.saveProductVariantMinInventoryQuantity = saveProductVariantMinInventoryQuantity;
        vm.saveProductPartnerMinInventoryQuantity = saveProductPartnerMinInventoryQuantity;
        vm.saveProductVariantPartnerMinInventoryQuantity = saveProductVariantPartnerMinInventoryQuantity;
        vm.saveProductMinOrderQuantity = saveProductMinOrderQuantity;
        vm.saveProductPartnerMinOrderQuantity = saveProductPartnerMinOrderQuantity;
        vm.saveProductExpirationDate = saveProductExpirationDate;
        vm.saveProductVariantExpirationDate = saveProductVariantExpirationDate;
        vm.validate = validate;
        vm.openQuantityEditDialog = openQuantityEditDialog;
        vm.openPriceEditDialog = openPriceEditDialog;
        vm.dateConvert = dateConvert;
        vm.open = open;
        vm.searchQuery = pagingParams.search;
        vm.currentSearch = pagingParams.search;
        vm.categories = [];
        vm.categoryTree = [];
        vm.opened = {};
        vm.childCategoryList = pagingParams.childCategoryList;
        vm.vendorsList = pagingParams.vendorsList;
        loadCategories();
        vm.onSelectCategory = onSelectCategory;
        vm.parentCategoryField = pagingParams.parentCategoryField;
        vm.childCategoryField = pagingParams.childCategoryField;
        vm.lang = $translate.use();
        vm.productStatuses = ["AVAILABLE", "OUT_OF_STOCK", "DISABLED", "SOLD_OUT"]
        vm.searchQuery = {
            productName: pagingParams.productName,
            isNameExclude: (pagingParams.isNameExclude === 'true'),
            category: pagingParams.category,
            vendors: pagingParams.vendors,
            sku: pagingParams.sku,
            barcode: pagingParams.barcode,
            statuses: pagingParams.statuses,
            parentCategories: pagingParams.parentCategories,
            childCategories: pagingParams.childCategories
        };
        if (pagingParams.firstRun
            && pagingParams.productName === null
            && pagingParams.sku === null) {
            getAllInitialVendors();
        } else {
            loadAll();
            getAllVendors()
        }
        loadAllPurchasingAgent();
        getSummaryTotals();

        function onSelectCategory() {
            var newChildCategoryList = [];
            vm.parentCategoryField.forEach(function (element) {
                var category = angular.fromJson(element.toString());
                newChildCategoryList.push.apply(newChildCategoryList, category.children);
            });
            if (vm.childCategoryList !== null && newChildCategoryList.length < vm.childCategoryList.length && vm.childCategoryField !== null) {
                vm.childCategoryList = vm.childCategoryList.filter(function(element) {
                    return !newChildCategoryList.some(function (child){ return (child.id === element.id)});
                });
                vm.childCategoryField = vm.childCategoryField.filter(function(element) {
                    element = angular.fromJson(element.toString());
                    return !vm.childCategoryList.some(function (child){ return (child.id === element.id)});
                });
            }
            vm.childCategoryList = newChildCategoryList;
            setTimeout(function () {
                $('#field_child_category').multiselect(
                    'setOptions',
                    {
                        selectAllText: all,
                        nonSelectedText: noneSelected,
                        allSelectedText: allSelected
                    });
                $('#field_child_category').multiselect('rebuild');
            }, 0);
        }

        function loadAllPurchasingAgent() {
            PurchasingAgent.getAllPurchasingAgents(onSuccess, onError);

            function onSuccess(data) {
                vm.purchasingAgents = data;
                vm.purchasingAgents.unshift({
                    id : null,
                    name : 'Not Set'
                });
            }

            function onError(error) {
                AlertService.error(error.data.message);
            }
        }

        function getSummaryTotals() {
            if (Principal.hasAnyAuthority(['ROLE_ADMIN'])) {
                ProductInventory.getSummaryTotals({
                    productName: vm.searchQuery.productName === null ? '' : vm.searchQuery.productName,
                    isNameExclude: vm.searchQuery.isNameExclude,
                    category: vm.searchQuery.category,
                    vendors: vm.searchQuery.vendors,
                    sku: vm.searchQuery.sku === null ? '' : vm.searchQuery.sku,
                    barcode: vm.searchQuery.barcode,
                    parentCategories: vm.searchQuery.parentCategories,
                    childCategories: vm.searchQuery.childCategories},
                    onSuccess, onError);
            }

            function onSuccess(data) {
                vm.summaryTotals = data;
            }

            function onError(error) {
                AlertService.error(error.data.message);
            }
        }

        function loadCategories() {
            Category.getAllCategories({}, function (data) {
                vm.categories = data;
                translateCategoryName();
                vm.categories.sort(function (a, b) {
                    return a.parentId > b.parentId ? 1 : -1;
                });
                for (var j = 0; j < vm.categories.length; j++) {
                    if (vm.categories[j].parentId === null) {
                        vm.categoryTree.push(vm.categories[j]);
                    } else {
                        var index = vm.categoryTree.findIndex(function (a) {
                            return a.id === vm.categories[j].parentId;
                        });
                        if (index > -1) {
                            if (vm.categoryTree[index].children === undefined) {
                                vm.categoryTree[index].children = [];
                            }
                            vm.categoryTree[index].children.push(vm.categories[j]);
                        }

                    }
                }
                setTimeout(function () {
                    $('#field_parent_category').multiselect(
                        'setOptions',
                        {
                            selectAllText: all,
                            nonSelectedText: noneSelected,
                            allSelectedText: allSelected
                        });
                    $('#field_parent_category').multiselect('rebuild');
                }, 0);
            });
        }

        function translateCategoryName() {
            var currentLang = $translate.use();
            for (var i = 0; i < vm.categories.length; i++) {
                if (currentLang === 'en') {
                    vm.categories[i]['name'] = vm.categories[i]['nameEng'];
                } else {
                    vm.categories[i]['name'] = vm.categories[i]['nameUzb'];
                }
            }
        }

        function loadAll() {
            ProductInventory.query({
                productName: vm.searchQuery.productName === null ? '' : vm.searchQuery.productName,
                isNameExclude: vm.searchQuery.isNameExclude,
                category: vm.searchQuery.category,
                vendors: vm.searchQuery.vendors,
                sku: vm.searchQuery.sku === null ? '' : vm.searchQuery.sku,
                barcode: vm.searchQuery.barcode,
                statuses: vm.searchQuery.statuses,
                parentCategories: vm.searchQuery.parentCategories,
                childCategories: vm.searchQuery.childCategories,
                page: pagingParams.page - 1,
                size: vm.itemsPerPage,
                sort: sort(),
                language: vm.lang
            }, onSuccess, onError);

            function sort() {
                var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
                return result;
            }

            function onSuccess(data, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                vm.queryCount = vm.totalItems;
                vm.products = data;
                vm.page = pagingParams.page;
                setExpirationDate();
            }

            function onError(error) {
                AlertService.error(error.data.message);
            }

        }

        function setExpirationDate() {
            for (var index = 0; index < vm.products.length; index++) {
                if (vm.products[index].expirationDate != null) {
                    vm.products[index].tmpExpirationDate = new Date(vm.products[index].expirationDate);
                } else {
                    vm.products[index].tmpExpirationDate = null;
                }
            }
        }

        function open($event, elementOpened){
            $event.preventDefault();
            $event.stopPropagation();

            vm.opened[elementOpened] = !vm.opened[elementOpened];
        }

        function dateConvert(date) {
            if (date != undefined) {
                var month = date.getMonth() + 1;
                var dateMonth = date.getDate();
                return date.getFullYear() + '.' + setZero(month) + '.' + setZero(dateMonth);
            } else {
                return '';
            }
        }

        function setZero(param) {
            if (param < 10) {
                return '0' + param;
            } else {
                return param;
            }
        }

        function loadPage(page) {
            vm.page = page;
            vm.transition();
        }

        function transition() {
            $state.transitionTo($state.$current, {
                page: vm.page,
                sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc'),
                productName: vm.searchQuery.productName === null ? '' : vm.searchQuery.productName,
                isNameExclude: vm.searchQuery.isNameExclude,
                category: vm.searchQuery.category,
                vendors: vm.searchQuery.vendors,
                sku: vm.searchQuery.sku === null ? '' : vm.searchQuery.sku,
                barcode: vm.searchQuery.barcode,
                statuses: vm.searchQuery.statuses,
                firstRun: false,
                parentCategoryField: vm.parentCategoryField,
                childCategoryField: vm.childCategoryField,
                vendorsList: vm.vendorsList,
                parentCategories: vm.searchQuery.parentCategories,
                childCategories: vm.searchQuery.childCategories,
                childCategoryList: vm.childCategoryList
            });
        }

        function search() {
            setCategory()
            vm.links = null;
            vm.page = 1;
            vm.predicate = 'sku';
            vm.reverse = true;
            if (vm.searchQuery.sku !== null) {
                vm.predicate = 'sku';
            }
            vm.transition();
        }

        function setCategory() {
            if (vm.parentCategoryField != null) {
                vm.searchQuery.parentCategories = [];
                vm.parentCategoryField.forEach(function (category) {
                    category = angular.fromJson(category.toString());
                    vm.searchQuery.parentCategories.push(category.id);
                });
            }
            if (vm.childCategoryField != null) {
                vm.searchQuery.childCategories = [];
                vm.childCategoryField.forEach(function (category) {
                    category = angular.fromJson(category.toString());
                    vm.searchQuery.childCategories.push(category.id);
                });
            }
        }

        function clear() {
            vm.links = null;
            vm.page = 1;
            vm.predicate = 'sku';
            vm.reverse = true;
            vm.parentCategoryField = null;
            vm.childCategoryField = null;
            vm.searchQuery = {
                productName: null,
                isNameExclude:  false,
                category: null,
                vendors: vm.vendorsList,
                sku: null,
                toDate: null,
                barcode: null,
                parentCategories: null,
                childCategories: null,
                statuses: ["AVAILABLE", "OUT_OF_STOCK", "SOLD_OUT"]
            };
            vm.childCategoryList = null;
            vm.transition();
            $('#field_vendor').multiselect('updateButtonText');
        }

        var unsubscribe = $rootScope.$on('translateChanged', function (event, lang) {
            vm.lang = lang;
            loadAll();
        });
        $rootScope.$on('$destroy', unsubscribe);

        function saveProductPurchasingAgent(productInventory) {
            var product = {
                "id": productInventory.productId,
                "purchasingAgentId": productInventory.purchasingAgentId,
                "expirationDate": productInventory.tmpExpirationDate,
                "minInventoryQuantity": productInventory.minInventoryQuantity,
                "partnerMinInventoryQuantity": productInventory.partnerMinInventoryQuantity,
                "minOrderQuantity": productInventory.minOrderQuantity,
                "partnerMinOrderQuantity": productInventory.partnerMinOrderQuantity
            };
            DepoService.updateB2bPrice(product, onSaveSuccess, onSaveError);
        }

        function saveProductStatus(productInventory) {
            var product = {
                "id": productInventory.productId,
                "stockStatus": productInventory.status,
                "purchasingAgentId": productInventory.purchasingAgentId,
                "expirationDate": productInventory.tmpExpirationDate,
                "minInventoryQuantity": productInventory.minInventoryQuantity,
                "partnerMinInventoryQuantity": productInventory.partnerMinInventoryQuantity,
                "minOrderQuantity": productInventory.minOrderQuantity,
                "partnerMinOrderQuantity": productInventory.partnerMinOrderQuantity
            };
            DepoService.updateB2bPrice(product, onSaveSuccess, onSaveError);
        }

        function saveProductMinInventoryQuantity(productInventory) {
            var product = {
                "id": productInventory.productId,
                "purchasingAgentId": productInventory.purchasingAgentId,
                "expirationDate": productInventory.tmpExpirationDate,
                "minInventoryQuantity": productInventory.minInventoryQuantity,
                "partnerMinInventoryQuantity": productInventory.partnerMinInventoryQuantity,
                "minOrderQuantity": productInventory.minOrderQuantity,
                "partnerMinOrderQuantity": productInventory.partnerMinOrderQuantity
            };
            DepoService.updateB2bPrice(product, onSaveSuccess, onSaveError);
        }

        function saveProductVariantMinInventoryQuantity(productInventory) {
            var product = {
                "id": productInventory.productId,
                "variantId": productInventory.variantId,
                "purchasingAgentId": productInventory.purchasingAgentId,
                "expirationDate": productInventory.tmpExpirationDate,
                "minInventoryQuantity": productInventory.minInventoryQuantity,
                "partnerMinInventoryQuantity": productInventory.partnerMinInventoryQuantity,
                "minOrderQuantity": productInventory.minOrderQuantity,
                "partnerMinOrderQuantity": productInventory.partnerMinOrderQuantity
            };
            DepoService.updateB2bPrice(product, onSaveSuccess, onSaveError);
        }

        function saveProductPartnerMinInventoryQuantity(productInventory) {
            var product = {
                "id": productInventory.productId,
                "purchasingAgentId": productInventory.purchasingAgentId,
                "expirationDate": productInventory.tmpExpirationDate,
                "minInventoryQuantity": productInventory.minInventoryQuantity,
                "partnerMinInventoryQuantity": productInventory.partnerMinInventoryQuantity,
                "minOrderQuantity": productInventory.minOrderQuantity,
                "partnerMinOrderQuantity": productInventory.partnerMinOrderQuantity
            };
            DepoService.updateB2bPrice(product, onSaveSuccess, onSaveError);
        }

        function saveProductVariantPartnerMinInventoryQuantity(productInventory) {
            var product = {
                "id": productInventory.productId,
                "variantId": productInventory.variantId,
                "purchasingAgentId": productInventory.purchasingAgentId,
                "expirationDate": productInventory.tmpExpirationDate,
                "minInventoryQuantity": productInventory.minInventoryQuantity,
                "partnerMinInventoryQuantity": productInventory.partnerMinInventoryQuantity,
                "minOrderQuantity": productInventory.minOrderQuantity,
                "partnerMinOrderQuantity": productInventory.partnerMinOrderQuantity
            };
            DepoService.updateB2bPrice(product, onSaveSuccess, onSaveError);
        }

        function saveProductMinOrderQuantity(productInventory) {
            var product = {
                "id": productInventory.productId,
                "purchasingAgentId": productInventory.purchasingAgentId,
                "expirationDate": productInventory.tmpExpirationDate,
                "minInventoryQuantity": productInventory.minInventoryQuantity,
                "partnerMinInventoryQuantity": productInventory.partnerMinInventoryQuantity,
                "minOrderQuantity": productInventory.minOrderQuantity,
                "partnerMinOrderQuantity": productInventory.partnerMinOrderQuantity
            };
            DepoService.updateB2bPrice(product, onSaveSuccess, onSaveError);
        }

        function saveProductPartnerMinOrderQuantity(productInventory) {
            var product = {
                "id": productInventory.productId,
                "purchasingAgentId": productInventory.purchasingAgentId,
                "expirationDate": productInventory.tmpExpirationDate,
                "minInventoryQuantity": productInventory.minInventoryQuantity,
                "partnerMinInventoryQuantity": productInventory.partnerMinInventoryQuantity,
                "minOrderQuantity": productInventory.minOrderQuantity,
                "partnerMinOrderQuantity": productInventory.partnerMinOrderQuantity
            };
            DepoService.updateB2bPrice(product, onSaveSuccess, onSaveError);
        }

        function saveProductSku(productInventory) {
            var product = {
                "id": productInventory.productId,
                "sku": productInventory.sku,
                "purchasingAgentId": productInventory.purchasingAgentId,
                "expirationDate": productInventory.tmpExpirationDate,
                "minInventoryQuantity": productInventory.minInventoryQuantity,
                "partnerMinInventoryQuantity": productInventory.partnerMinInventoryQuantity,
                "minOrderQuantity": productInventory.minOrderQuantity,
                "partnerMinOrderQuantity": productInventory.partnerMinOrderQuantity
            };
            DepoService.updateB2bPrice(product, onSaveSuccess, onSaveError);
        }

        function saveProductVariantSku(productInventory) {
            var product = {
                "id": productInventory.productId,
                "variantId": productInventory.variantId,
                "sku": productInventory.sku,
                "purchasingAgentId": productInventory.purchasingAgentId,
                "expirationDate": productInventory.tmpExpirationDate,
                "minInventoryQuantity": productInventory.minInventoryQuantity,
                "partnerMinInventoryQuantity": productInventory.partnerMinInventoryQuantity,
                "minOrderQuantity": productInventory.minOrderQuantity,
                "partnerMinOrderQuantity": productInventory.partnerMinOrderQuantity
            };
            DepoService.updateB2bPrice(product, onSaveSuccess, onSaveError);
        }

        function saveProductBarcode(productInventory) {
            var product = {
                "id": productInventory.productId,
                "barcode": productInventory.barcode,
                "purchasingAgentId": productInventory.purchasingAgentId,
                "expirationDate": productInventory.tmpExpirationDate,
                "minInventoryQuantity": productInventory.minInventoryQuantity,
                "partnerMinInventoryQuantity": productInventory.partnerMinInventoryQuantity,
                "minOrderQuantity": productInventory.minOrderQuantity,
                "partnerMinOrderQuantity": productInventory.partnerMinOrderQuantity
            };
            DepoService.updateB2bPrice(product, onSaveSuccess, onSaveError);
        }

        function saveProductVariantBarcode(productInventory) {
            var product = {
                "id": productInventory.productId,
                "variantId": productInventory.variantId,
                "barcode": productInventory.barcode,
                "purchasingAgentId": productInventory.purchasingAgentId,
                "expirationDate": productInventory.tmpExpirationDate,
                "minInventoryQuantity": productInventory.minInventoryQuantity,
                "partnerMinInventoryQuantity": productInventory.partnerMinInventoryQuantity,
                "minOrderQuantity": productInventory.minOrderQuantity,
                "partnerMinOrderQuantity": productInventory.partnerMinOrderQuantity
            };
            DepoService.updateB2bPrice(product, onSaveSuccess, onSaveError);
        }

        function saveProductShippingTag(productInventory) {
            var product = {
                "id": productInventory.productId,
                "shippingTag": productInventory.shippingTag,
                "purchasingAgentId": productInventory.purchasingAgentId,
                "expirationDate": productInventory.tmpExpirationDate,
                "minInventoryQuantity": productInventory.minInventoryQuantity,
                "partnerMinInventoryQuantity": productInventory.partnerMinInventoryQuantity,
                "minOrderQuantity": productInventory.minOrderQuantity,
                "partnerMinOrderQuantity": productInventory.partnerMinOrderQuantity
            };
            DepoService.updateB2bPrice(product, onSaveSuccess, onSaveError);
        }

        function saveProductVariantShippingTag(productInventory) {
            var product = {
                "id": productInventory.productId,
                "variantId": productInventory.variantId,
                "shippingTag": productInventory.shippingTag,
                "purchasingAgentId": productInventory.purchasingAgentId,
                "expirationDate": productInventory.tmpExpirationDate,
                "minInventoryQuantity": productInventory.minInventoryQuantity,
                "partnerMinInventoryQuantity": productInventory.partnerMinInventoryQuantity,
                "minOrderQuantity": productInventory.minOrderQuantity,
                "partnerMinOrderQuantity": productInventory.partnerMinOrderQuantity
            };
            DepoService.updateB2bPrice(product, onSaveSuccess, onSaveError);
        }

        function saveProductExpirationDate(productInventory) {
            var product = {
                "id": productInventory.productId,
                "purchasingAgentId": productInventory.purchasingAgentId,
                "expirationDate": productInventory.tmpExpirationDate,
                "minInventoryQuantity": productInventory.minInventoryQuantity,
                "partnerMinInventoryQuantity": productInventory.partnerMinInventoryQuantity,
                "minOrderQuantity": productInventory.minOrderQuantity,
                "partnerMinOrderQuantity": productInventory.partnerMinOrderQuantity
            };
            DepoService.updateB2bPrice(product, onSaveSuccess, onSaveError);
        }

        function saveProductVariantExpirationDate(productInventory) {
            var product = {
                "id": productInventory.productId,
                "variantId": productInventory.variantId,
                "purchasingAgentId": productInventory.purchasingAgentId,
                "expirationDate": productInventory.tmpExpirationDate,
                "minInventoryQuantity": productInventory.minInventoryQuantity,
                "partnerMinInventoryQuantity": productInventory.partnerMinInventoryQuantity,
                "minOrderQuantity": productInventory.minOrderQuantity,
                "partnerMinOrderQuantity": productInventory.partnerMinOrderQuantity
            };
            DepoService.updateB2bPrice(product, onSaveSuccess, onSaveError);
        }

        function saveProductPurchasePrice(productInventory) {
            var product = {
                "id": productInventory.productId,
                "purchasePrice": productInventory.purchasePrice,
                "purchasingAgentId": productInventory.purchasingAgentId,
                "expirationDate": productInventory.tmpExpirationDate,
                "minInventoryQuantity": productInventory.minInventoryQuantity,
                "partnerMinInventoryQuantity": productInventory.partnerMinInventoryQuantity,
                "minOrderQuantity": productInventory.minOrderQuantity,
                "partnerMinOrderQuantity": productInventory.partnerMinOrderQuantity
            };
            DepoService.updateB2bPrice(product, onSaveSuccess, onSaveError);
        }

        function saveProductVariantPurchasePrice(productInventory) {
            var product = {
                "id": productInventory.productId,
                "variantId": productInventory.variantId,
                "purchasePrice": productInventory.purchasePrice,
                "purchasingAgentId": productInventory.purchasingAgentId,
                "expirationDate": productInventory.tmpExpirationDate,
                "minInventoryQuantity": productInventory.minInventoryQuantity,
                "partnerMinInventoryQuantity": productInventory.partnerMinInventoryQuantity,
                "minOrderQuantity": productInventory.minOrderQuantity,
                "partnerMinOrderQuantity": productInventory.partnerMinOrderQuantity
            };
            DepoService.updateB2bPrice(product, onSaveSuccess, onSaveError);
        }

        function onSaveSuccess() {
            loadAll();
        }

        function onSaveError() {
            loadAll();
        }

        function excel() {
            vm.searchQuery.language = vm.lang;
            vm.searchQuery.productName = vm.searchQuery.productName === null ? '' : vm.searchQuery.productName
            vm.searchQuery.sku = vm.searchQuery.sku === null ? '' : vm.searchQuery.sku
            setCategory();
            $http({
                url: 'api/products/inventory-excel',
                method: 'POST',
                responseType: 'arraybuffer',
                data: vm.searchQuery,
                headers: {
                    'Content-type': 'application/json'
                }
            }).success(function (data) {
                var blob = new Blob([data], {
                    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
                });

                saveAs(blob, 'product-inventory-' + DateUtils.currentTimestamp() + '.xlsx');
            }).error(function () {
            });

        }

        setTimeout(function () {
            translateMultiSelect();

            $('#productStatus').multiselect({
                buttonClass: "mt-multiselect btn btn-default",
                includeSelectAllOption: true,
                dropRight: false,
                buttonWidth: '100%',
                selectAllText: translate('product.all'),
                nonSelectedText: translate('product.noneSelected'),
                allSelectedText: translate('product.allSelected')
            });

            $('#field_vendor').multiselect({
                buttonClass: "mt-multiselect btn btn-default",
                includeSelectAllOption: true,
                dropRight: false,
                buttonWidth: '100%',
                selectAllText: translate('product.all'),
                nonSelectedText: translate('product.noneSelected'),
                allSelectedText: translate('product.allSelected'),
                maxHeight: 300
            });

            $('#field_parent_category').multiselect({
                buttonClass: "mt-multiselect btn btn-default",
                includeSelectAllOption: true,
                dropRight: false,
                buttonWidth: '100%',
                selectAllText: translate('product.all'),
                nonSelectedText: translate('product.noneSelected'),
                allSelectedText: translate('product.allSelected'),
                maxHeight: 300
            });

            $('#field_child_category').multiselect({
                buttonClass: "mt-multiselect btn btn-default",
                includeSelectAllOption: true,
                dropRight: false,
                buttonWidth: '100%',
                selectAllText: translate('product.all'),
                nonSelectedText: translate('product.noneSelected'),
                allSelectedText: translate('product.allSelected'),
                maxHeight: 300
            });

        }, 0);

        function getAllVendors() {
            Vendor.getAsList({}, onSuccess, onError);

            function onSuccess(data) {
                vm.vendors = data;
                vm.vendors.push({id: -1, name: "Not Specified"});
                setTimeout(function () {
                    $('#field_vendor').multiselect(
                        'setOptions',
                        {
                            selectAllText: all,
                            nonSelectedText: noneSelected,
                            allSelectedText: allSelected
                        });
                    $('#field_vendor').multiselect('rebuild');
                }, 0);
            }

            function onError(error) {
                AlertService.error(error.data.message);
            }
        }

        function getAllInitialVendors() {
            Vendor.getAsList({}, onSuccess, onError);

            function onSuccess(data) {
                vm.vendors = data;
                vm.vendors.push({id: -1, name: "Not Specified"});
                getVendorIds(vm.vendors);
                setTimeout(function () {
                    $('#field_vendor').multiselect(
                        'setOptions',
                        {
                            selectAllText: all,
                            nonSelectedText: noneSelected,
                            allSelectedText: allSelected
                        });
                    $('#field_vendor').multiselect('rebuild');
                }, 0);
            }

            function onError(error) {
                AlertService.error(error.data.message);
            }
        }

        function getVendorIds(vendors) {
            vm.vendorsList = [];
            vendors.forEach(function (vendor) {
                vm.vendorsList.push(vendor.id.toString());
            });
            vm.searchQuery.vendors = vm.vendorsList;
        }


        function translateMultiSelect() {
            all = translate('product.all');
            noneSelected = translate('product.noneSelected');
            allSelected = translate('product.allSelected');
        }
        function validate(price) {
            if (price === null) {
                return "This field is required";
            }
        }

        function openQuantityEditDialog(id, variantId, totalQuantity, name, variantName, sku, purchasingAgentId, expirationDate, minInventoryQuantity, partnerMinInventoryQuantity, minOrderQuantity, partnerMinOrderQuantity) {
            $uibModal.open({
                templateUrl: 'app/entities/product/product-quantity-dialog.html',
                controller: 'ProductQuantityDialogController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'md',
                resolve: {
                    productId: [function() {
                        return id;
                    }],
                    variantId: [function(){
                        return variantId;
                    }],
                    totalQuantity: [function () {
                        return totalQuantity;
                    }],
                    name: [function() {
                        return name;
                    }],
                    variantName: [function() {
                        return variantName;
                    }],
                    sku: [function() {
                        return sku;
                    }],
                    purchasingAgentId: [function () {
                        return purchasingAgentId;
                    }],
                    expirationDate: [function () {
                        return expirationDate;
                    }],
                    minInventoryQuantity: [function () {
                        return minInventoryQuantity;
                    }],
                    partnerMinInventoryQuantity: [function () {
                        return partnerMinInventoryQuantity;
                    }],
                    minOrderQuantity: [function () {
                        return minOrderQuantity;
                    }],
                    partnerMinOrderQuantity: [function () {
                        return partnerMinOrderQuantity;
                    }],
                }
            }).result.then(function () {
                loadAll();
            });
        }

        function openPriceEditDialog(id, variantId, purchasePrice, retailPrice, salePrice, salePercent, isSale, vatIncluded, name, variantName, purchasingAgentId, expirationDate, minInventoryQuantity, partnerMinInventoryQuantity, minOrderQuantity, partnerMinOrderQuantity) {
            $uibModal.open({
                templateUrl: 'app/entities/product/product-price-dialog.html',
                controller: 'ProductPriceDialogController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'lg',
                resolve: {
                    productId: [function () {
                        return id;
                    }],
                    variantId: [function () {
                        return variantId;
                    }],
                    purchasePrice: [function () {
                        return purchasePrice;
                    }],
                    retailPrice: [function () {
                        return retailPrice;
                    }],
                    salePrice: [function () {
                        return salePrice;
                    }],
                    salePercent: [function () {
                        return salePercent;
                    }],
                    isSale: [function () {
                        return isSale;
                    }],
                    vatIncluded: [function () {
                        return vatIncluded;
                    }],
                    name: [function () {
                        return name;
                    }],
                    variantName: [function () {
                        return variantName;
                    }],
                    purchasingAgentId: [function () {
                        return purchasingAgentId;
                    }],
                    expirationDate: [function () {
                        return expirationDate;
                    }],
                    minInventoryQuantity: [function () {
                        return minInventoryQuantity;
                    }],
                    partnerMinInventoryQuantity: [function () {
                        return partnerMinInventoryQuantity;
                    }],
                    minOrderQuantity: [function () {
                        return minOrderQuantity;
                    }],
                    partnerMinOrderQuantity: [function () {
                        return partnerMinOrderQuantity;
                    }],
                    entity: ['DepoService', function (DepoService) {
                        return DepoService.query({
                            productId: id,
                            variantId: (variantId === 0 ? null : variantId)
                        }).$promise;
                    }],
                }
            }).result.then(function () {
                loadAll();
            });
        }

        function isAccess(){
            return Principal.hasAnyAuthority(['ROLE_ADMIN', 'ROLE_MANAGER', 'ROLE_AGENT_UZB', 'ROLE_AGENT_KOR', 'ROLE_MERCHANT', 'ROLE_SELLER', 'ROLE_CUSTOMER_SUPPORT_BOZORAKA', 'ROLE_CUSTOMER_SUPPORT_MOBPAY', 'ROLE_CONTENT_EDITOR_MOBPAY', 'ROLE_CONTENT_EDITOR_BOZORAKA', 'ROLE_MARKET_MANAGER', 'ROLE_HANPASS_MANAGER', 'ROLE_ANALYST']);
        }

        function isAdmin(){
            return Principal.hasAnyAuthority(['ROLE_ADMIN']);
        }

        function isManager(){
            return Principal.hasAnyAuthority(['ROLE_MANAGER', 'ROLE_MARKET_MANAGER']);
        }
    }
})();
