(function() {
    'use strict';

    angular
        .module('bozorakaAdminApp')
        .controller('FlashSaleDialogController', FlashSaleDialogController);

    FlashSaleDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'entity', 'FlashSale', 'productName', 'retailPrice', 'variants'];

    function FlashSaleDialogController ($timeout, $scope, $stateParams, $uibModalInstance, entity, FlashSale, productName, retailPrice, variants) {
        var vm = this;

        vm.flashSale = entity;
        vm.productName = productName;
        vm.retailPrice = retailPrice;
        vm.variants =  variants;
        vm.clear = clear;
        vm.datePickerOpenStatus = {};
        vm.openCalendar = openCalendar;
        vm.save = save;
        vm.calculateSalePrice = calculateSalePrice;
        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });
        vm.validateDates = validateDates;
        vm.datePickerOpenStatus = {};
        vm.openCalendar = openCalendar;
        function openCalendar(date) {
            vm.datePickerOpenStatus[date] = true;
        }
        function validateDates() {
            vm.dateError = null;

            if (vm.flashSale.startTime && vm.flashSale.endTime) {
                if (vm.flashSale.startTime > vm.flashSale.endTime) {
                    vm.dateError = "Start date cannot be after the end date.";
                } else if (vm.flashSale.endTime < vm.flashSale.startTime) {
                    vm.dateError = "End date cannot be before the start date.";
                }
            }
        }

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function save () {
            vm.isSaving = true;
            var productFlashSale = {
                "startTime": vm.flashSale.startTime,
                "endTime": vm.flashSale.endTime,
                "customerType": vm.flashSale.customerType,
                "discountPercentage": vm.flashSale.discountPercentage,
                "status": vm.flashSale.status,
                "visibleState": "ACTIVE",
                "createdBy": vm.flashSale.createdBy,
                "createdDate": vm.flashSale.createdDate,
                "lastModifiedBy": vm.flashSale.lastModifiedBy,
                "lastModifiedDate": vm.flashSale.lastModifiedDate,
                "productId": vm.flashSale.productId,
                "id": vm.flashSale.id
            }
            if(vm.flashSale.startTime != null && vm.flashSale.endTime != null && vm.flashSale.discountPercentage != null){
                if(vm.flashSale.status ==null) {
                    productFlashSale.status = "UPCOMING";
                }
                FlashSale.update(productFlashSale, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            $scope.$emit('bozorakaAdminApp:flashSaleUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }

        vm.datePickerOpenStatus.startTime = false;
        vm.datePickerOpenStatus.endTime = false;
        vm.datePickerOpenStatus.createdDate = false;
        vm.datePickerOpenStatus.lastModifiedDate = false;

        function calculateSalePrice() {
            if (vm.retailPrice && vm.flashSale.discountPercentage) {
                return vm.retailPrice - (vm.retailPrice * (vm.flashSale.discountPercentage / 100));
            } else {
                return 0;
            }
        }

        vm.close = function() {
            $uibModalInstance.dismiss('cancel');
        };
    }
})();
